import React from 'react';
import ReactDOM from 'react-dom/client';
import Layout from './src/components/Layout';
import './src/styles/global.css';

const addScript = (url) => {
   const script = document.createElement('script');
   script.src = url;
   script.async = true;
   document.body.appendChild(script);
};

export const onClientEntry = () => {
   window.onload = () => {
      addScript('https://fast.wistia.com/assets/external/E-v1.js');
      addScript('https://widget.clutch.co/static/js/widget.js');
   };
};

export const replaceHydrateFunction = () => {
   return (element, container) => {
      const root = ReactDOM.createRoot(container);
      root.render(element);
   };
};

export const wrapPageElement = ({ element, props }) => {
   return <Layout {...props}>{element}</Layout>;
};
