import React from 'react';
import PropTypes from 'prop-types';
import { Lenis as ReactLenis } from '@studio-freight/react-lenis';

import '../styles/global.css';

import Navbar from './Navbar';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const Layout = ({ children }) => {
   return (
     <ReactLenis
       root
       options={{
         duration: 1.4,
         easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
       }}
     >
       <Helmet>
         <script
           type="text/javascript"
           id="hs-script-loader"
           async
           defer
           src="//js.hs-scripts.com/45162419.js"
         ></script>
       </Helmet>
       <Navbar />
       <main>{children}</main>
       <Footer />
     </ReactLenis>
   );
};

Layout.propTypes = {
   children: PropTypes.any,
};

export default Layout;
