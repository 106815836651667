import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Sidebar from './Sidebar';

const Navbar = () => {
   const [navbar, setNavbar] = useState(false);

   const changeBackground = () => {
      if (window.scrollY >= 23) {
         setNavbar(true);
      } else {
         setNavbar(false);
      }
   };
   useEffect(() => {
      changeBackground();
      window.addEventListener('scroll', changeBackground);
   });
   return (
      <>
         <div
            className={`sticky z-50 mx-auto w-full transition-all duration-500 top-0 py-4 ${
               navbar ? 'border-b-4 border-brand bg-brand-dark top-0 py-4' : 'bg-transparent'
            }`}
         >
            <div className='flex flex-row items-center justify-between max-w-7xl mx-auto px-4'>
               <div>
                  <Link to='/' aria-label='Link to Homepage' className='flex flex-row items-center md:gap-x-2 basis-1/4'>
                     <StaticImage src='../images/StudioLewis Icon-White.png' className='w-10 lg:w-12' alt='Studio Lewis Logo' />
                     <div className='uppercase text-white font-ttNorms-bold leading-[1.1em] text-[18px] lg:text-[21px]'>
                        Studio
                        <br />
                        <span className='text-brand-light'>Lewis</span>
                     </div>
                  </Link>
               </div>
               <nav className='hidden md:flex flex-row items-center lg:space-x-16 md:space-x-12'>
                  <Link to='/' className='link uppercase font-ttNorms-bold text-[10px] lg:text-xs text-white tracking-widest hover'>
                     Home
                  </Link>
                  <Link to='/portfolio' className='link uppercase font-ttNorms-bold text-[10px] lg:text-xs text-white tracking-widest hover'>
                     Portfolio
                  </Link>
                  <Link to='/about' className='link uppercase font-ttNorms-bold text-[10px] lg:text-xs text-white tracking-widest hover'>
                     About
                  </Link>
                  <Link to='/pricing' className='link uppercase font-ttNorms-bold text-[10px] lg:text-xs text-white tracking-widest hover'>
                     Pricing
                  </Link>
                  {/*<Link to='/blog' className='link uppercase font-ttNorms-bold text-[10px] lg:text-xs text-white tracking-widest hover'>
                     Blog
                  </Link> */}
               </nav>
               <div>
                  <Link to='/contact' className='button hidden md:inline-block text-[9px] lg:text-xs'>
                     Book an Intro Call
                  </Link>
               </div>
               <Sidebar />
            </div>
         </div>
      </>
   );
};

export default Navbar;
