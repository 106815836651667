import React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <footer className="w-full bg-brand-dark px-4 py-20">
      <div className="mx-auto max-w-7xl space-y-8 md:space-y-10">
        <p className="mx-auto text-center font-rubik text-sm uppercase tracking-widest text-white md:text-base md:tracking-[0.5em]">
          A Film By
        </p>
        <h3 className="mx-auto text-center font-ttNorms-bold text-7xl text-white md:text-9xl">
          Studio{" "}
          <span className="relative z-10">
            Lewis{" "}
            <span className="text-stroke-2 text-stroke-brand-light text-fill-transparent absolute bottom-2 left-[3px] -z-10 text-brand-light md:top-[22px]">
              Lewis
            </span>
          </span>
        </h3>
        <hr className="mx-auto block max-w-xs md:hidden" />
        <div className="mx-auto flex max-w-5xl flex-col items-center justify-between space-y-5 font-ttNorms uppercase text-white md:flex-row md:space-y-0">
          <Link to="/" className="link">
            Home
          </Link>
          <Link to="/portfolio" className="link">
            Portfolio
          </Link>
          <Link to="/about" className="link">
            About
          </Link>
          <Link to="/pricing" className="link">
            Pricing
          </Link>
          {/* <Link to='/blog' className='link'>
                  Blog
               </Link> */}
        </div>
        <hr className="mx-auto block max-w-xs md:hidden" />
        <div className="mx-auto flex max-w-md flex-col items-center justify-between space-y-5 font-ttNorms uppercase text-white md:flex-row md:space-y-0">
          <a
            href="https://www.linkedin.com/company/studio-lewis"
            className="link"
          >
            LinkedIn
          </a>
          <a href="mailto:contact@connor-lewis.com" className="link">
            Email
          </a>
        </div>
        <div className="mx-auto hidden flex-row items-center justify-between space-x-2 uppercase text-white md:flex">
          <div className="flex flex-col items-center justify-center text-center font-ttNorms uppercase tracking-wide">
            <p className="text-[9px]  text-gray-200">Directed By</p>
            <p className="font-ttNorms-bold text-sm text-white">
              Experienced storytellers
            </p>
          </div>
          <div className="flex flex-col items-center justify-center text-center font-ttNorms uppercase tracking-wide">
            <p className="text-[9px]  text-gray-200">Produced By</p>
            <p className="font-ttNorms-bold text-sm text-white">
              Organized project managers
            </p>
          </div>
          <div className="flex flex-col items-center justify-center text-center font-ttNorms uppercase tracking-wide">
            <p className="text-[9px]  text-gray-200">Edited By</p>
            <p className="font-ttNorms-bold text-sm text-white">
              Creative masterminds
            </p>
          </div>
        </div>
        <p className="mx-auto text-center text-base uppercase tracking-[0.5em] text-white">
          Leave them with a feeling.
        </p>
      </div>
      <div className="mt-10 flex max-w-7xl flex-col items-center justify-between space-y-5 text-[10px] md:flex-row md:space-y-0">
        <p>© 2024 by Studio Lewis, LLC</p>
        <p>Made by Reardon Web Development</p>
      </div>
    </footer>
  );
};

export default Footer;
