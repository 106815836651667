import React, { useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

const containerVariants = {
   visible: {
      opacity: 1,
      transition: {
         delayChildren: 0.25,
         staggerChildren: 0.25,
      },
   },
   hidden: { opacity: 0 },
};

const itemVariants = {
   visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.35 },
   },
   hidden: {
      opacity: 0,
      x: 20,
   },
};

const Sidebar = () => {
   const [showSidebar, setShowSidebar] = useState(false);

   const closeSidebar = () => {
      setShowSidebar(false);
   };

   return (
      <div className='relative block md:hidden'>
         {showSidebar ? (
            <button
               aria-label='Close Menu'
               className='fixed right-5 top-6 z-50 flex cursor-pointer items-center text-4xl font-light text-white'
               onClick={() => {
                  setShowSidebar(!showSidebar);
               }}
            >
               <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='h-8 w-8'>
                  <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
               </svg>
            </button>
         ) : (
            <svg
               aria-label='Open Menu'
               onClick={() => {
                  setShowSidebar(!showSidebar);
               }}
               className='relative z-50 flex h-8 w-8 cursor-pointer items-center text-white'
               xmlns='http://www.w3.org/2000/svg'
               viewBox='0 0 24 24'
               strokeWidth='1.5'
               stroke='currentColor'
            >
               <path strokeLinecap='round' strokeLinejoin='round' d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5' />
            </svg>
         )}

         <div className={`fixed top-0 right-0 z-40 h-full w-[100vw] duration-300 ease-in-out ${showSidebar ? 'translate-x-0 ' : 'translate-x-full'}`}>
            <StaticImage
               src='../images/Frame-3.png'
               objectPosition='25% 50%'
               className='h-full grayscale z-10 absolute'
               alt='Connor Lewis Directing'
            />
            <div className='bg-gradient-to-r from-black to-transparent  z-20 absolute inset-0 bg-opacity-50' />
            <motion.nav
               variants={containerVariants}
               initial='hidden'
               whileInView='visible'
               className='absolute z-50 mx-auto flex w-full flex-col items-start space-y-12 text-3xl pb-4 uppercase tracking-widest text-white top-1/2 transform -translate-y-1/2  left-10'
            >
               <motion.div variants={itemVariants}>
                  <StaticImage src='../images/StudioLewis Icon-White.png' alt='Studio Lewis Icon' className='w-12' />
               </motion.div>
               <motion.div variants={itemVariants}>
                  <Link to='/' className='font-thin' activeClassName='font-rubik' onClick={closeSidebar}>
                     Home
                  </Link>
               </motion.div>
               <motion.div variants={itemVariants}>
                  <Link to='/portfolio' className='font-thin' activeClassName='font-rubik' onClick={closeSidebar}>
                     Portfolio
                  </Link>
               </motion.div>
               <motion.div variants={itemVariants}>
                  <Link to='/about' className='font-thin' activeClassName='font-rubik' onClick={closeSidebar}>
                     About
                  </Link>
               </motion.div>
               <motion.div variants={itemVariants}>
                  <Link to='/pricing' className='font-thin' activeClassName='font-rubik' onClick={closeSidebar}>
                     Pricing
                  </Link>
               </motion.div>
               {/* <motion.div variants={itemVariants}>
                  <Link to='/blog' className='font-thin' activeClassName='font-rubik' onClick={closeSidebar}>
                     Blog
                  </Link>
               </motion.div>*/}

               <motion.div variants={itemVariants}>
                  <Link to='/contact' className='font-thin' activeClassName='font-rubik' onClick={closeSidebar}>
                     Contact
                  </Link>
               </motion.div>
            </motion.nav>
         </div>
      </div>
   );
};

export default Sidebar;
